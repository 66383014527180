// react-image-gallery
@import "~react-image-gallery/styles/css/image-gallery.css";
.photoSlide .image-gallery-right-nav,
.photoSlide .image-gallery-left-nav {
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.4);
}
.photoSlide .image-gallery-left-nav .image-gallery-svg,
.photoSlide .image-gallery-right-nav .image-gallery-svg {
  width: 32px;
  height: 51px;
  @media screen and (max-width: 576px) {
    width: 13px;
    height: 32px;
  }
}
.photoSlide .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  aspect-ratio: 1.7;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  aspect-ratio: 1.7;
}
.photoSlide .fslightbox-toolbar-button.fslightbox-flex-centered:first-child {
  display: none;
}
:not(:root):fullscreen::backdrop {
  height: 100%;
}
.fslightbox-open .cms_item.photoSlide {
  height: 100vh;
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container {
  top: 81px;
  @media screen and (max-width: 576px) {
    top: 44px;
  }
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container .fslightbox-source {
  transform: translateY(-57px);
}
.image-gallery-slide-wrapper,
.image-gallery-thumbnails-wrapper {
  max-width: 960px;
  margin: 0 auto;
}
